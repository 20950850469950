import Head from 'next/head';

interface StructuredDataProps {
  data: Array<object>;
}

const StructuredData: React.FC<StructuredDataProps> = ({ data }) => {
  return (
    <Head>
      {data.map((item, index) => (
        <script
          key={`structured-data${index}`}
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(item) }}
        />
      ))}
    </Head>
  );
};

export default StructuredData;
